import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import router from "./router";
import store from "./store";
import "@/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import { i18n } from "./locale/index.js";
import Vuelidate from "vuelidate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CountryFlag from "vue-country-flag";
import VueApexCharts from "vue-apexcharts";
import "@/global.scss";
import localStorageHelper from "./plugins/LocalStorageHelpers";
import '@mdi/font/css/materialdesignicons.css'
import VueMask from "v-mask";
import FlagIcon from "vue-flag-icon";
import {ExportExcel} from "./mixins/exportToExcel.mixin.js";
Vue.use(FlagIcon);
Vue.use(VueMask);
Vue.mixin(ExportExcel)
Vue.use(VueApexCharts);
Vue.use(localStorageHelper);


Vue.component("apexchart", VueApexCharts);
Vue.component("country-flag", CountryFlag);
Vue.component("loading-overlay", Loading);
Vue.use(Vuelidate);
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
Vue.config.productionTip = false;
Vue.config.devtools = false;
