import axios from "axios";
// import { APIService } from "../../../service/api";
// import urlConstant from "../../../constant/url.constant";
import transactionService from "../../../services/TransactionService/transactionService";

const state = {
  nationality: [],
  base_url: process.env.VUE_APP_BASEURL,
  transaction_url: process.env.VUE_APP_TRANSACATION_PORT,
  sourceFunds: [],
  occupation: [],
  country: [],
  relationship: [],
  transactionDetails: {},
  Idtypes: [],
};

const mutations = {
  setTransactionDetails(state, payload) {
    state.transactionDetails = { ...payload };
  },
  registerRemitte(state, payload) {
    switch (payload.type) {
      case "REGISTERING_REMITTE":
        state.remitteResponse = {
          isRegistering: true,
          errorRegistering: false,
          remitteResponse: {},
        };
        break;
      case "REGISTERED_REMITTE":
        state.remitteResponse = {
          isRegistering: false,
          errorRegistering: false,
          remitteResponse: payload.data,
        };
        break;
    }
  },

  getNationality(state, payload) {
    switch (payload.type) {
      case "GETTING_NATIONALITY":
        state.nationality = {
          isRegistering: true,
          errorRegistering: false,
          list: {},
        };
        break;
      case "GET_NATIONALITY":
        state.nationality = {
          isRegistering: false,
          errorRegistering: false,
          list: payload.data,
        };
        break;
    }
  },
  getidtype(state, payload) {
    switch (payload.type) {
      case "GETTING_ID_TYPES":
        state.Idtypes = {
          isRegistering: true,
          errorRegistering: false,
          list: {},
        };
        break;
      case "GET_ID_TYPES":
        state.Idtypes = {
          isRegistering: false,
          errorRegistering: false,
          list: payload.data,
        };
        break;
    }
  },

  getSourceFunds(state, payload) {
    switch (payload.type) {
      case "GETTING_SOURCE_FUNDS":
        state.sourceFunds = {
          isRegistering: true,
          errorRegistering: false,
          funds: {},
        };
        break;
      case "GET_SOURCE_FUNDS":
        state.sourceFunds = {
          isRegistering: false,
          errorRegistering: false,
          funds: payload.data,
        };
        break;
    }
  },
  getOccupationData(state, payload) {
    switch (payload.type) {
      case "GETTING_OCCUPATION_LIST":
        state.occupation = {
          isRegistering: true,
          errorRegistering: false,
          occupation: {},
        };
        break;
      case "GET_OCCUPATION_LIST":
        state.occupation = {
          isRegistering: false,
          errorRegistering: false,
          occupation: payload.data,
        };
        break;
    }
  },
  getCountryList(state, payload) {
    switch (payload.type) {
      case "GET_COUNTRY_LIST":
        state.country = {
          isRegistering: false,
          errorRegistering: false,
          country: payload.data,
        };
        break;
    }
  },
  getRelationshipList(state, payload) {
    switch (payload.type) {
      case "GETTING_RELATIONSHIP_LIST":
        state.relationship = {
          isLoading: true,
          error: false,
          response: {},
        };
        break;
      case "GET_RELATIONSHIP_LIST":
        state.relationship = {
          isLoading: false,
          error: false,
          response: payload.data,
        };
        break;
    }
  },
};

// };

const getters = {
  transactionDetails: (state) => state.transactionDetails,
  nationality_list: (state) => state.nationality,

  source_list: (state) => state.sourceFunds,

  occupation_list: (state) => state.occupation,

  country_list: (state) => state.country,

  realtion_list: (state) => state.relationship,
  idtype_list: (state) => state.Idtypes,
};

const actions = {
  async getNationalityList({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/all-countries`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getNationality", {
        type: "GET_NATIONALITY",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getIDtypesMyanmer({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=RDOC`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getidtype", {
        type: "GET_ID_TYPES",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getIDtypes({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=DOC`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getidtype", {
        type: "GET_ID_TYPES",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getPostalCodes() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/all-zipcode`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  setTransactionDetails({ commit }, transactionDetails) {
    commit("setTransactionDetails", transactionDetails);
  },
  async getTransactionSummary() {
    try {
      let response = await transactionService.getTransactionSummary();
      return response;
    } catch (e) {
      return e;
    }
  },
  async pay(comit, payload) {
    console.log({ comit });
    try {
      // const token = sessionStorage.getItem("access_token");
      const responseData = await transactionService.Payonline(payload);
      console.log("hype", responseData);
      return responseData;
    } catch (e) {
      console.log("hype err", e);
      return e;
    }
  },
  async getTansactionDetails(comit, txn_id) {
    console.log({ comit });
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/txn/list-transactions-summery?skip=0&limit=100&transaction_id=${txn_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getTansaction(comit, txn_id) {
    console.log({ comit });
    try {
      const responseData = await transactionService.getTransactionbyId({
        transaction_id: txn_id,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getSourceOfFunds({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/sub_category/source-of-funds`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getSourceFunds", {
        type: "GET_SOURCE_FUNDS",
        data: responseData.data,
      });
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async SourceOfFundsValues() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-bus-parameter-data?parameter_code=SOF`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async SOFindValues() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-parameter-data?parameter_code=SOF`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getnatureOfBusiness() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-bus-parameter-data?parameter_code=NOB`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },

  async getTransactionFieldDetails(commit, txn_id) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/transaction/transaction-by-txn-id/${txn_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },

  async getProfileData() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/dashboard/profile/status`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getDashboardDetails() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/txn/user-dashboard-details`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getRecentTransactions() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/txn/recent-transactions-summery`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getNotificationStatus() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/get-notification`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async PayFromWallet(commit, payload) {
    try {
      // const token = sessionStorage.getItem("access_token");
      const responseData = await transactionService.PayFromWallet(payload);
      return responseData;
    } catch (e) {
      return e.response;
    }
  },
  async getOccupation({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=OCC`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getOccupationData", {
        type: "GET_OCCUPATION_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async get_remittence_reason() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/sub_category/remittence-reason`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async get_remittence_reasonSendpay() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=POR`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getSOF() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=SOF`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return e;
    }
  },
  async getCountryService({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1/service_charge/get-all-country`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getCountryList", {
        type: "GET_COUNTRY_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getRelationship({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-dropdown?parameter_code=REL`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getRelationshipList", {
        type: "GET_RELATIONSHIP_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
