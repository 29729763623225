// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
import VueI18n from "vue-i18n";
import Vue from "vue";
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../locale",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
// 2. Create i18n instance with options
export const i18n = new VueI18n({
  locale: sessionStorage.getItem("App_Language")
    ? sessionStorage.getItem("App_Language")
    : "en-us",
  silentFallbackWarn: true,
  fallbackLocale: "en-us",
  messages: loadLocaleMessages(),
});
