import ExcelJS from "exceljs/dist/exceljs.min.js";
export const ExportExcel = {
  methods: {
    async exportToExcel(headers, rowdata, sheetname) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(
        sheetname ? sheetname : sheetname,
        {
          properties: { tabColor: { argb: "4b3589" } },
        }
      );
      // Add header row
      worksheet.columns = headers.map((ele) => {
        return {
          header: ele.text,
          key: ele.value,
        };
      });
      // Add data rows
      rowdata.map((row) => {
        const myObject = {};
        headers.map((headkey) => {
          const val = headkey.value;
          myObject[val] = row[val];
        });
        console.log("myObject", myObject);
        const values = Object.values(myObject);
        worksheet.addRow(values);
      });
      // Generate a Blob from the Excel workbook
      const blob = await workbook.xlsx.writeBuffer();

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = sheetname + ".xlsx";
      link.click();
    },
  },
};
