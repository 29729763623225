<template>
  <!-- <v-app class="grey lighten-4"><router-view></router-view></v-app> -->
  <v-app>
    <router-view></router-view>
    <v-snackbar
      v-model="alert.show"
      top
      rounded="pill"
      centered
      :color="alert.color"
      dense
    >
      <v-alert :type="alert.color" class="mb-0 ma-0 pa-0" dense>{{
        alert.message
      }}</v-alert>
      <template v-slot:action="{ attrs }">
        <v-btn depressed icon v-bind="attrs" @click="alert.show = !alert.show">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const timeoutInMS = 15 * 60 * 1000; // 1 minutes -> 1 * 60 * 1000
let timeoutId;
let routes = [
  "/login",
  "/forgotpassword",
  "/updatepassword",
  "/signup",
  "/OTPVerification",
  "/TwoFactorAuth",
  "/evolet",
  "/registration",
];
let is_partner = sessionStorage.getItem("is_partner")
  ? sessionStorage.getItem("is_partner")
  : false;
function handleInactive() {
  //except these routes if location is any place it will exit.
  let path = window.location.pathname;
  if (!routes.includes(path)) {
    if (is_partner) {
      console.log("Partner User");
    } else {
      //to come back to main login page
      console.log(window.location);
      sessionStorage.clear();
      window.location.pathname = "/login";
      // clearTimeout(timeoutId);
    }
  } else {
    console.log("hi");
  }
}

function startTimer() {
  // setTimeout returns an ID (can be used to start or clear a timer)
  timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() {
  clearTimeout(timeoutId);
  startTimer();
}

function setupTimers() {
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);

  startTimer();
}
export default {
  name: "AppView",
  data: () => {
    return {
      isPropmtClose: false,
    };
  },
  created() {},
  mounted() {
    setupTimers();
    console.log("eer", this.$route);
  },
  computed: {
    ...mapState(["alert"]),
  },
  methods: {
    ewalletKycModal() {
      const h = this.$createElement;
      this.$bvModal
        .msgBoxConfirm(h("h5", "Please complete the profile.", {}), {
          title: "",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Proceed",
          cancelVariant: "danger",
          cancelTitle: "Do later",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then((val) => {
          if (val) {
            this.$router.push("/kyc");
          }
          this.isPropmtClose = false;
        });
    },
  },
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  --font-family-monospace: Consolas, Menlo, Monaco, Lucida Console,
    Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New,
    Courier, monospace;
  /* user-select: none; */
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  color: #696969;
}
.v-main {
  padding-top: 0px !important;
}
</style>
