import axios from "axios";
// import { APIService } from "../../../service/api";
// import urlConstant from "../../../constant/url.constant";

const state = {
  businessKycresponse: {},
  businesFileUploadResponse: {},
  base_url: process.env.VUE_APP_BASEURL,
  business_user_port: process.env.VUE_APP_BUSINESS_USER_PORT,
};

const mutations = {
  addBusinessKYC(state, payload) {
    switch (payload.type) {
      case "ADDING_BUSINESS_KYC":
        state.businessKycresponse = {
          isRegistering: true,
          errorRegistering: false,
          responseBusiness: {}
        };
        break;
      case "ADD_BUSINESS_KYC":
        state.remitteResponse = {
          isRegistering: false,
          errorRegistering: false,
          responseBusiness: payload.data
        };
        break;
    }
  },
  updateBusinessKYC(state, payload) {
    switch (payload.type) {
      case "UPDATEING_BUSINESS_KYC":
        state.businessKycresponse = {
          isRegistering: true,
          errorRegistering: false,
          responseBusiness: {}
        };
        break;
      case "UPDATE_BUSINESS_KYC":
        state.remitteResponse = {
          isRegistering: false,
          errorRegistering: false,
          responseBusiness: payload.data
        };
        break;
    }
  },
  

  addFiles(state, payload) {
    switch (payload.type) {
      case "ADDING_FILES":
        state.businesFileUploadResponse = {
          isRegistering: true,
          errorRegistering: false,
          fileResponse: {}
        };
        break;
      case "ADD_FILES":
        state.businesFileUploadResponse = {
          isRegistering: false,
          errorRegistering: false,
          FileSystem: payload.data
        };
        break;
    }
  },
};

const getters = {
  remitte_details: (state) => {
    return state.remitteResponse;
  }
}

const actions = {
  async addBusinessUserKyc({ commit }, _businessDetails) {
    try {
      const token = sessionStorage.getItem('access_token');
      const responseData = await axios.post(`${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business_user_kyc/add_business_user_kyc`, _businessDetails, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json',
        }
      });
      commit("addBusinessKYC", { type: "ADD_BUSINESS_KYC", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async updateBusinessUserKyc({ commit }, _businessDetails) {
    try {
      const token = sessionStorage.getItem('access_token');
      const responseData = await axios.put(`${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business_user_kyc/update_business_user_kyc`, _businessDetails, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json',
        }
      });
      commit("updateBusinessKYC", { type: "UPDATE_BUSINESS_KYC", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async uploadBusinessDoc({ commit }, _businessUpload) {
    console.log('store', _businessUpload);
    try {
      const token = sessionStorage.getItem('access_token');
      const responseData = await axios.post(`${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business_user_kyc/upload_business_user_docs`, _businessUpload, {
        headers: {
          'Content-Type':'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        }
      });
      commit("addFiles", { type: "ADD_FILES", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getBusinessUserKyc() {
    try {
      const token = sessionStorage.getItem('access_token');
      const responseData = await axios.get(`${process.env.VUE_APP_BUSINESS_USER_SERVICE}/api/v1/business_user_kyc/get_business_user_kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json',
        }
      });
      return responseData.data.data[0];
    } catch (e) {
      return e;
    }
  },
  async getIndividualUserKyc() {
    try {
      const token = sessionStorage.getItem('access_token');
      const responseData = await axios.get(`${process.env.VUE_APP_USER_SERVICE}/api/v1/user/get-draft-profile-data`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'accept': 'application/json' }
      });
      return responseData.data.data[0];
    } catch (e) {
      return e;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};