import request from "@/Axios/request";
const txnbaseUrl = `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/txn`;
const paymentUrl = `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1/online`;
const paymentBaseUrl = `${process.env.VUE_APP_PAYMENT_GATEWAY_SERVICE}/api/v1`;
const txnServiceUrl = `${process.env.VUE_APP_TRANSACATION_SERVICE}/api/v1`;
export default {
  // ---------------Transaction Summary page integrations-------------------//
  getTransactionSummary() {
    return request({
      url: `${txnbaseUrl}/list-transactions-summery?skip=0&limit=100000`,
      method: "get",
    });
  },
  getTransactionDetails(txn_id) {
    return request({
      url: `${txnbaseUrl}/list-transactions-summery?skip=0&limit=100&transaction_id=${txn_id}`,
      method: "get",
    });
  },
  getTransactionbyId(params) {
    return request({
      url: `${txnbaseUrl}/get-transaction-summery-by-id`,
      method: "get",
      params: params,
    });
  },
  uploadSuspisusFile(suspect_remarks, transaction_id, data) {
    return request({
      url: `${txnbaseUrl}/txn/remarks?remarks=${suspect_remarks}&transaction_id=${transaction_id}`,
      method: "post",
      data: data,
    });
  },
  // ------------------Payment Send money Flow Summary page --------------------------------//
  PayFromWallet(data) {
    return request({
      url: `${paymentUrl}/payment/evolet`,
      method: "post",
      data: data,
    });
  },
  Payonline(data) {
    return request({
      url: `${paymentUrl}/payment`,
      method: "post",
      data: data,
    });
  },
  uploadOnbehalfFirstPage(txn_id, firstPage) {
    return request({
      url: `${paymentBaseUrl}/qqpay/upload/on-behalf/document?transaction_id=${txn_id}`,
      method: "post",
      firstPage,
    });
  },
  uploadDocument(txn_id, type, bankAdvice) {
    return request({
      url: `${paymentUrl}/upload-bank-receipt?transaction_id=${txn_id}&doc_type=${type}`,
      method: "post",
      data: bankAdvice,
    });
  },

  //-------------------------- Send Money ------------------------------------//

  getGrapExchangeRate() {
    return request({
      url: `${txnServiceUrl}/service_charge/get_all_by_country/IND`,
      method: "get",
    });
  },

  //------------------------- transaction feilds --------------------------------------//

  getTransactionFeildDetails(txn_id) {
    return request({
      url: `${txnServiceUrl}/transaction/transaction-by-txn-id/${txn_id}`,
      method: "get",
    });
  },
  // ----------------payin and payout data -----------------------------
  getpayinSummary(params) {
    return request({
      url: `${paymentUrl}/get-payin-summery`,
      method: "get",
      params: params,
    });
  },
  Addpayin(data) {
    return request({
      url: `${paymentUrl}/pay-in`,
      method: "POST",
      data: data,
    });
  },
  payoutBeneficiary(description, data) {
    return request({
      url: `${paymentUrl}/pay-out-beneficiary?description=${description}`,
      method: "POST",
      data: data,
    });
  },
  payoutinitiate(description, data) {
    return request({
      url: `${paymentUrl}/pay-out?description=${description}`,
      method: "POST",
      data: data,
    });
  },
  getPayoutSummary(params) {
    return request({
      url: `${paymentUrl}/get-payout-summery`,
      method: "get",
      params: params,
    });
  },
  //------------------get balance --------------------------------------//
  getBalance() {
    return request({
      url: `${paymentUrl}/balance`,
      method: "get",
    });
  },
  getBalanceHistory(params) {
    return request({
      url: `${paymentUrl}/transaction-summery`,
      method: "get",
      params: params,
    });
  },
  //------------------Bulk UPload --------------------------------------//
  getBulkSummary() {
    return request({
      url: `${paymentUrl}/bulk-payout-summery`,
      method: "get",
    });
  },
  uploadPaymentFile(data) {
    return request({
      url: `${paymentUrl}/bulk-payout`,
      method: "POST",
      data: data,
    });
  },
  VerifyPaymentFile(data) {
    return request({
      url: `${paymentUrl}/bulk-payout-validate`,
      method: "POST",
      data: data,
    });
  },
  getRecordPayoutSummary(params) {
    return request({
      url: `${paymentUrl}/bulk-payout-summery-by-status`,
      method: "get",
      params: params,
    });
  },
};
