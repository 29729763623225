import request from "@/Axios/request";
const baseUrl = `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bene`;
const busBeneUrl = `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/bus-bene`;
const BeneficiaryUrl = `${process.env.VUE_APP_REMITTE_USER_SERVICE}/api/v1/beneficiary`;
export default {
  // ----------------------------Beneficiary Summary-----------------------------------
  getBeneLimit() {
    return request({
      url: `${baseUrl}/get-remittee-limit`,
      method: "get",
    });
  },
  getremitteeList() {
    return request({
      url: `${baseUrl}/remitter/get-remittee-list`,
      method: "get",
    });
  },
  getRemitteDetailByID(remitteeID) {
    return request({
      url: `${baseUrl}/get-receiver-by-id/${remitteeID}`,
      method: "get",
    });
  },
  deleteRemittee(remitteeId) {
    return request({
      url: `${baseUrl}/delete-beneficiary/${remitteeId}`,
      method: "DELETE",
    });
  },
  // --------------------------------Create Business Beneficiary---------------------------------
  createBusRemitte(data) {
    return request({
      url: `${busBeneUrl}/business-beneficiary`,
      data,
      method: "post",
    });
  },
  updateBusRemittee(data) {
    return request({
      url: `${busBeneUrl}/business-beneficiary/${data.remitteID}`,
      data,
      method: "put",
    });
  },

  // ------------------------------------ Create Personal Beneficiary ----------------------------//
  createRemittee(data) {
    return request({
      url: `${baseUrl}/create-remitte`,
      data,
      method: "post",
    });
  },

  updateRemittee(data) {
    return request({
      url: `${baseUrl}/update-beneficiary/${data.remitteID}`,
      data,
      method: "put",
    });
  },

  //-----------------------Send Money -------------------------------------------//

  getBenePolicyNo(policy_number) {
    return request({
      url: `${baseUrl}/get-receiver-by-policy?policy_number=${policy_number}`,
      method: "get",
    });
  },
  getBeneDetails(country_name, payment_mode) {
    return request({
      url: `${baseUrl}/remitter/get-remittee-list/by-country?country=${country_name}&payment_mode=${payment_mode}`,
      method: "get",
    });
  },
  addBeneficiary(data) {
    return request({
      url: `${BeneficiaryUrl}/create`,
      method: "post",
      data: data,
    });
  },
  getBeneficiary(params) {
    return request({
      url: `${BeneficiaryUrl}/get-all`,
      method: "get",
      params: params,
    });
  },
  updateBeneficiary(data, id) {
    return request({
      url: `${BeneficiaryUrl}/update?receiver_id=${id}`,
      method: "put",
      data: data,
    });
  },
  deleteBeneficiary(id) {
    return request({
      url: `${BeneficiaryUrl}/delete?receiver_id=${id}`,
      method: "delete",
    });
  },
};
