// globalzing the localstorage
export default {
  install(Vue) {
    // to set value in storage
    Vue.prototype.$setLocalStorage = function (key, value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    };
    // to get value in storage
    Vue.prototype.$getLocalStorage = function (key) {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    };
    // to remove specific value in storage
    Vue.prototype.$removeLocalStorage = function (key) {
      sessionStorage.removeItem(key);
    };
    // to clear storage
    Vue.prototype.$clearLocalStorage = function () {
      sessionStorage.clear();
    };
  },
};
