import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/authentication/Login.vue"),
  },
  // {
  //   path: "*",
  //   name: "PageNotFound",
  //   component: () => import("../components/tools/PageNotFound.vue"),
  // },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../views/authentication/ForgotPassword.vue"),
  },
  {
    path: "/updatepassword",
    name: "UpdatePassword",
    component: () => import("../views/authentication/UpdatePassword.vue"),
  },
  {
    path: "/createpassword",
    name: "CreatePassword",
    component: () => import("../views/authentication/CreatePassword.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("../views/authentication/SignUp.vue"),
  },

  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/authentication/Registration.vue"),
  },
  {
    path: "/OTPVerification",
    name: "OTP Verification",
    component: () => import("../views/authentication/OTPVerification.vue"),
  },
  {
    path: "/TwoFactorAuth",
    name: "Two Factor Auth",
    component: () => import("../views/authentication/TwoFactorAuth.vue"),
  },
  {
    path: "/evolet",
    name: "Evolet User",
    component: () => import("../components/EvoletEnter.vue"),
  },
  {
    path: "/",
    redirect: "/home",
    name: "Layout",
    component: () => import("../views/Layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/home",
        name: "HomeView",
        component: () => import("../views/HomeView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/add-indivisual-user",
        name: "CreateUserMain",
        component: () => import("../views/createUser/createUserMain.vue"),
        // component: CreateUserMain,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/business-user-info",
        name: "BusUserInfo",
        component: () =>
          import("../components/BusinessUserflow/CreateBusinessuser.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/help",
        name: "Help",
        component: () => import("../views/settings/Help.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/profile/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/kyc",
        name: "KYC",
        component: () => import("../views/profile/KYC.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/payout",
        name: "payout",
        component: () => import("../components/Payouttxn/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/payins",
        name: "Payins",
        component: () => import("../components/Payins/payins.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/balance",
        name: "Balance",
        component: () => import("../components/Payins/Balance.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log(store.state)
  console.log("Cookie: ", document.cookie);
  const isCookieHasToken = await document.cookie.includes("token");
  console.log("isCookieHasToken: ", isCookieHasToken);
  console.log("Cookie: ", document.cookie);

  if (isCookieHasToken) {
    await document.cookie.split(";").forEach((i) => {
      const data = i.split("=");
      const key = data[0].toString().trim();
      const value = data[1].toString().trim();
      console.log("cookie data: ", data);
      console.log("key: ", key);
      console.log("value: ", value);
      sessionStorage.setItem(key, value);
    });
  }

  const authenticatedUser = await sessionStorage.getItem("access_token");
  sessionStorage.setItem("prevPath", from.path);
  console.log("authenticatedUser ", authenticatedUser);
  // const previousPath=fro
  const requiresAuth = await to.matched.some(
    (record) => record.meta.requiresAuth
  );
  console.log("requiresAuth", requiresAuth);
  console.log("next path", to);
  console.log("path", from);

  if (requiresAuth && !authenticatedUser && !isCookieHasToken) {
    next("/login");
  } else if (!requiresAuth && authenticatedUser) {
    next("/home");
  } else {
    next();
  }
});

export default router;
