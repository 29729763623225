import Vue from "vue";
import Vuex from "vuex";
import Common from "./modules/common";
import Authentication from "./modules/authentication/index";
import Remittee from "./modules/Remittee/index";
import BusinessKyc from "./modules/BusinessKyc/index";
import Transactional from "./modules/Transactional/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    access_token: "",
    user_email: "",
    complete_registration: false,
    data: {},
    userDetails: {},
    selctedPayMode: {},
    CreatedBeneDetails: null,
    showDialog: false,
    receipientListItems: [],
    alert: { show: false, color: "info", message: "" },
    selectedUserType: "",
    paymodeCountry: {
      paymode: "",
      country: "",
    },
    buscreatecountry: "",
  },
  getters: {
    getpaymodeCountry: (state) => state.paymodeCountry,
  },

  mutations: {
    access_token(state, payload) {
      state.access_token = payload;
    },
    userType(state, newData) {
      state.selectedUserType = newData;
    },
    user_email(state, payload) {
      state.user_email = payload;
    },
    complete_registration(state, payload) {
      state.complete_registration = payload;
    },
    SET_RECEIPIENT_LIST(state, payload) {
      state.receipientListItems = payload;
    },
    setUserDetails(state, data) {
      state.userDetails = data;
    },
    setAlert(state, data) {
      Object.assign(state.alert, { color: "info", show: true }, data);
      state.alert = data;
    },
  },
  actions: {
    userType({ commit }, newData) {
      commit("userType", newData);
    },
  },
  modules: {
    Common,
    Authentication,
    Remittee,
    BusinessKyc,
    Transactional,
  },
});
