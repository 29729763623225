import axios from "axios";

const state = {
  emailRegisterResponse: {},
  registerAccountResponse: {},
  loginResponse: {},
  forgotPassword: {},
  updatepwdResponse: {},
  language: "",
  profileResponse: {},
  forgotResponse: {},
  validOTPResponse: {},
  allKycUser: {},
  country: {},
  state: {},
  city: {},
  countryChart: {},
  base_url: process.env.VUE_APP_BASEURL,
  user_port: process.env.VUE_APP_USER_PORT,
  transaction_port: process.env.VUE_APP_TRANSACATION_PORT,
  user_email: "",
};

const mutations = {
  user_email(state, payload) {
    state.user_email = payload;
  },
  registerEmployer(state, payload) {
    switch (payload.type) {
      case "REGISTERING_EMPLOYER":
        state.registerResponse = {
          isRegistering: true,
          errorRegistering: false,
          emailRegisterResponse: {},
        };
        break;
      case "REGISTERED_EMPLOYER":
        state.registerResponse = {
          isRegistering: false,
          errorRegistering: false,
          emailRegisterResponse: payload.data,
        };
        break;
    }
  },
  registerAccount(state, payload) {
    switch (payload.type) {
      case "REGISTERING_ACCOUNT":
        state.registerAccountResponse = {
          isRegistering: true,
          errorRegistering: false,
          resgisterResponse: {},
        };
        break;
      case "REGISTERED_ACCOUNT":
        state.registerAccountResponse = {
          isRegistering: false,
          errorRegistering: false,
          resgisterResponse: payload.data,
        };
        break;
    }
  },
  loginAccount(state, payload) {
    switch (payload.type) {
      case "LOGING_ACCOUNT":
        state.loginResponse = {
          isLoging: true,
          errorRegistering: false,
          loginResponsePayload: {},
        };
        break;
      case "LOGIN_ACCOUNT":
        state.loginResponse = {
          isLoging: false,
          errorRegistering: false,
          loginResponsePayload: payload.data,
        };
        break;
    }
  },
  resetPassword(state, payload) {
    switch (payload.type) {
      case "RESETING_PASSWORD":
        state.forgotPassword = {
          isLoging: true,
          errorRegistering: false,
          resetPasswordResponse: {},
        };
        break;
      case "RESET_PASSWORD":
        state.loginResponse = {
          isLoging: false,
          errorRegistering: false,
          resetPasswordResponse: payload.data,
        };
        break;
    }
  },
  updatePassword(state, payload) {
    switch (payload.type) {
      case "UPDATING_PASSWORD":
        state.updatepwdResponse = {
          isLoging: true,
          errorRegistering: false,
          resetPasswordResponse: {},
        };
        break;
      case "UPDATE_PASSWORD":
        state.updatepwdResponse = {
          isLoging: false,
          errorRegistering: false,
          resetPasswordResponse: payload.data,
        };
        break;
    }
  },
  updateLanguage(state, payload) {
    switch (payload.type) {
      case "UPDATE_LANGUAGE":
        state.language = payload.data;
        break;
    }
  },

  updateProfile(state, payload) {
    switch (payload.type) {
      case "UPDATING_PROFILE":
        state.profileResponse = {
          isLoging: true,
          errorRegistering: false,
          profile: {},
        };
        break;
      case "UPDATE_PROFILE":
        state.profileResponse = {
          isLoging: false,
          errorRegistering: false,
          profile: payload.data,
        };
        break;
    }
  },
  forgotPassowrd(state, payload) {
    switch (payload.type) {
      case "FORGOT_PASSWORD":
        state.forgotResponse = {
          isLoging: false,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  validOTP(state, payload) {
    switch (payload.type) {
      case "VALID_OTP":
        state.validOTPResponse = {
          isLoging: false,
          errorRegistering: false,
          data: payload.data,
        };
        break;
    }
  },
  getCountryList(state, payload) {
    switch (payload.type) {
      case "GET_COUNTRY_LIST":
        state.country = {
          isRegistering: false,
          errorRegistering: false,
          country: payload.data,
        };
        break;
    }
  },
  getBeneficiaryCountries(state, payload) {
    switch (payload.type) {
      case "GET_BENEFICIARY_COUNTRIES":
        state.country = {
          isRegistering: false,
          errorRegistering: false,
          country: payload.data,
        };
        break;
    }
  },
  getStateList(state, payload) {
    switch (payload.type) {
      case "GET_STATE_LIST":
        state.state = {
          isRegistering: false,
          errorRegistering: false,
          response: payload.data,
        };
        break;
    }
  },
  getCityList(state, payload) {
    switch (payload.type) {
      case "GET_CITY_LIST":
        state.city = {
          isRegistering: false,
          errorRegistering: false,
          response: payload.data,
        };
        break;
    }
  },
  docsubmit(state, payload) {
    switch (payload.type) {
      case "DOC_SUBMIT":
        state.city = {
          isRegistering: false,
          errorRegistering: false,
          response: payload.data,
        };
        break;
    }
  },

  getCountryChart(state, payload) {
    switch (payload.type) {
      case "GET_COUNTRY_CHART":
        state.countryChart = {
          isLoading: false,
          error: false,
          response: payload.data,
        };
        break;
    }
  },
};

const getters = {
  user_details: (state) => {
    return state.emailRegisterResponse;
  },
  user_profile: (state) => state.profileResponse,

  country_list: (state) => state.country,

  state_list: (state) => state.state,

  city_list: (state) => state.city,
  user_email: (state) => state.user_email,
};

const actions = {
  saveUserEmail({ commit }, _userEmail) {
    commit("user_email", _userEmail);
  },
  async registerEmail({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/validate-email?user_email=${_userDetails.user_email}`,
        _userDetails
      );
      commit("registerEmployer", {
        type: "REGISTERED_EMPLOYER",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async registerMobile({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/verify-mobile?mobile_number=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
        _userDetails
      );
      commit("registerEmployer", {
        type: "REGISTERED_EMPLOYER",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async CheckTFAstatus({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/get-two-factor?mobile=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
        _userDetails
      );
      commit("registerAccount", {
        type: "REGISTERED_ACCOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async UpadteTFAstatus({ commit }, _userDetails) {
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/enable-two-factor?mobile=${_userDetails.mobile_number}&two_factor_status=${_userDetails.status}&user_type=${_userDetails.user_type}`,
        _userDetails
      );
      commit("registerAccount", {
        type: "REGISTERED_ACCOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async registerAccount({ commit }, _userDetails) {
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/register`,
        _userDetails
      );
      commit("registerAccount", {
        type: "REGISTERED_ACCOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async registerAccount_mobile({ commit }, _userDetails) {
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/register-mobile`,
        _userDetails
      );
      commit("registerAccount", {
        type: "REGISTERED_ACCOUNT",
        data: responseData,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async login({ commit }, _userDetails) {
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/login`,
        _userDetails
      );
      commit("loginAccount", { type: "LOGIN_ACCOUNT", data: responseData });
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async get_otp_2FA({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/two-step-auth/get-otp?user_email=${_userDetails.user_email}`,
        _userDetails
      );
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async verifyEmail_profile({ commit }, _userDetails) {
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/verify-email?user_email=${_userDetails.user_email}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        _userDetails
      );
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async confirm_otp_Email({ commit }, _userDetails) {
    const token = sessionStorage.getItem("access_token");
    try {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/email-verify/confirm-otp`,
        method: "POST",
        data: _userDetails,
        params: _userDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async get_otp_Mobile2FA({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/two-step-auth/get-otp-mobile?mobile_number=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
        _userDetails
      );
      commit("user_mobile", _userDetails.mobile_number);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async confirm_otp_Mobile2FA({ commit }, _userDetails) {
    try {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/two-step-auth/confirm-otp-mobile`,
        method: "POST",
        data: _userDetails,
        params: _userDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      commit("user_mobile", _userDetails.mobile_number);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async confirm_otp_2FA({ commit }, _userDetails) {
    try {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/two-step-auth/confirm-otp`,
        method: "POST",
        data: _userDetails,
        params: _userDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async get_otpMobile({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/mobile/get-otp?mobile_number=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
        _userDetails
      );
      commit("user_mobile", _userDetails.mobile_number);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async get_otp({ commit }, _userDetails) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/email/get-otp?user_email=${_userDetails.user_email}`,
        _userDetails
      );
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async confirm_otp_mobile({ commit }, _userDetails) {
    try {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/mobile/confirm-otp?mobile_number`,
        method: "POST",
        data: _userDetails,
        params: _userDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      commit("user_mobile", _userDetails.mobile_number);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async confirm_otp({ commit }, _userDetails) {
    try {
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/email/confirm-otp?user_email`,
        method: "POST",
        data: _userDetails,
        params: _userDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      commit("user_email", _userDetails.user_email);
      return responseData;
    } catch (error) {
      console.log("err", error);
      return error;
    }
  },
  async forgotPassword({ commit }, _userDetails) {
    try {
      const mobile_number = _userDetails.mobile_number;
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/forget-password/get-otp-mobile?mobile_number=${mobile_number}&user_type=${_userDetails.user_type}`,
        mobile_number
      );
      commit("forgotPassowrd", { type: "FORGOT_PASSWORD", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async validateOtpPassword({ commit }, _otpDetails) {
    try {
      // const user_email = _otpDetails.user_email;
      // const otpValue = _otpDetails.otp;
      const responseData = await axios({
        url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/forget-password/confirm-otp`,
        method: "POST",
        data: _otpDetails,
        params: _otpDetails,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // const responseData = await axios.post(
      //   `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/forget-password/confirm-otp`,
      //   _otpDetails
      // );
      commit("validOTP", { type: "VALID_OTP", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async changePassword({ commit }, _userDetails) {
    // console.log('userDetao', _userDetails)
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/forget-password`,
        _userDetails
      );
      commit("resetPassword", { type: "RESET_PASSWORD", data: responseData });
      console.log("store", responseData);
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async ekycUpdate({ commit }, _kycDetails) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/ekyc`,
        _kycDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("updatePassword", { type: "UPDATE_PASSWORD", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async ekycDocSubmit({ commit }, _kycDetails) {
    try {
      console.log("inside of the api call", _kycDetails);
      const token = sessionStorage.getItem("access_token");
      console.log(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/ekyc/upload?doc_type=ID_PROOF`
      );
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/ekyc/upload?doc_type=ID_PROOF`,
        _kycDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("docsubmit", { type: "DOC_SUBMIT", data: responseData });
      return responseData;
    } catch (e) {
      console.log(e);
      return e;
    }
  },
  async uploadProfileImage({ commit }, _businessUpload) {
    console.log("store", _businessUpload);
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/profile/upload`,
        _businessUpload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("filesss", responseData);
      commit("updatePassword", { type: "UPDATE_PASSWORD", data: responseData });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async ekycDocSubmitSelfi({ commit }, _kycDetails) {
    try {
      console.log("inside of the api call", _kycDetails);
      const token = sessionStorage.getItem("access_token");
      console.log(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/ekyc/upload?doc_type=FACE_IMAGE`
      );
      const responseData = await axios.post(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/ekyc/upload?doc_type=FACE_IMAGE`,
        _kycDetails,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("docsubmit", { type: "DOC_SUBMIT", data: responseData });
      return responseData;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  async setLanguage({ commit }, _langauge) {
    try {
      commit("updateLanguage", { type: "UPDATE_LANGUAGE", data: _langauge });
    } catch (e) {
      return e;
    }
  },

  async getProfile({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      if (token) {
        const responseData = await axios.get(
          `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              accept: "application/json",
            },
          }
        );
        commit("updateProfile", {
          type: "UPDATE_PROFILE",
          data: responseData.data,
        });
        return responseData;
      }
    } catch (e) {
      return e;
    }
  },
  async getCountryService({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-all-country-data`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getCountryList", {
        type: "GET_COUNTRY_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getBeneficiaryCountryList({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-all-country`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getBeneficiaryCountries", {
        type: "GET_BENEFICIARY_COUNTRIES",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getcountryExchangeRate({ commit }) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-exchange-rates?send_country=MYS`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getBeneficiaryCountries", {
        type: "GET_BENEFICIARY_COUNTRIES",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getStateById({ commit }, id) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE_OLD}/api/v1/user/all-states?country_id=${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getStateList", {
        type: "GET_STATE_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async getStateByIso3({ commit }, id) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-all-states?country_code=${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getStateList", {
        type: "GET_STATE_LIST",
        data: responseData.data,
      });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getCityByIso3({ commit }, id) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown/get-all-cities?country_code=${id.country}&state_code=${id.state}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getCityList", { type: "GET_CITY_LIST", data: responseData.data });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getCityById({ commit }, id) {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE_OLD}/api/v1/user/all-cities?state_id=${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      commit("getCityList", { type: "GET_CITY_LIST", data: responseData.data });
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getEvolteUser() {
    try {
      const token = sessionStorage.getItem("access_token");
      const responseData = await axios.get(
        `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/get-draft-profile-data`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return responseData.data;
    } catch (e) {
      console.log("ee", e);
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
