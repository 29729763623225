import axios from "axios";
import router from "../router";
let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const service = axios.create({
  withCredentials: true,
  timeout: 0, // no timeout
  headers: {
    "content-type": "application/json",
  },
});

service.interceptors.request.use(
  (config) => {
    const authToken = sessionStorage.getItem("access_token");
    config.headers["Authorization"] = "Bearer " + authToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  async function (res) {
    if (
      (res && res.data && res.data.status_code === 200) ||
      (res && res.data && res.data.access_token)
    ) {
      return res;
    }
    if (
      res &&
      res.data &&
      (res.data.status_code === 401 || res.data.status_code === 403)
    ) {
      if (router.currentRoute.meta && router.currentRoute.meta.requiresAuth) {
        const originalConfig = res.config;

        if (!originalConfig._retry) {
          if (!isRefreshing) {
            originalConfig._retry = true;
            isRefreshing = true;
            const token = sessionStorage.getItem("refresh_token");
            if (token) {
              try {
                const response = await axios.post(
                  `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/refresh-token?token=${token}`,
                  {},
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                if (response.data.access_token) {
                  const newAccessToken = response.data.access_token;
                  sessionStorage.setItem("access_token", newAccessToken);
                  axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${newAccessToken}`;
                  processQueue(null, newAccessToken);

                  // Update the original request with the new token
                  originalConfig.headers[
                    "Authorization"
                  ] = `Bearer ${newAccessToken}`;
                  return axios(originalConfig);
                } else {
                  processQueue(new Error("Session out"), null);
                  return res;
                }
              } catch (err) {
                processQueue(err, null);
                throw err;
              } finally {
                isRefreshing = false;
              }
            }
          }
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              // Retry the original request with the new token
              originalConfig.headers["Authorization"] = `Bearer ${token}`;
              return axios(originalConfig);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        return Promise.reject(res);
      } else {
        return res;
      }
    }
    return res;
  },
  function (error) {
    // forbidden
    if (error.response.status == 403) {
      //route to forbidden page
    }

    return Promise.reject(error.response.data);
  }
);

export default service;
